/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

function ImageComponent({ currentImage, newImage, formFieldName }) {
  const apiRoot = useSelector((state) => state.env.api_root);

  const [opened, { open, close }] = useDisclosure(false);

  if (!(currentImage?.newImage?.size > 0) && _.isEmpty(currentImage.image)) {
    return (
      <div
        style={{
          width: 80,
          height: 80,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: 5,
          backgroundColor: "#E0E0E0",
        }}
      >
        <FontAwesomeIcon icon={faCamera} color="#b0b0b0" size="xl" />
      </div>
    );
  }

  if (_.has(currentImage, "newImage")) {
    return (
      <>
        <div>
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <img
            src={URL.createObjectURL(currentImage.newImage)}
            alt={"preview"}
            width={80}
            height={80}
            style={{ borderRadius: 8, cursor: "pointer" }}
            onClick={open}
          />
        </div>

        <Modal opened={opened} onClose={close} title={formFieldName} size="auto">
          <Group wrap="nowrap" mt="md">
            <img
              src={URL.createObjectURL(newImage)}
              alt={"preview"}
              width={450}
              height={500}
              style={{ borderRadius: 8 }}
            />
          </Group>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div>
        <img
          src={currentImage.image.image ? apiRoot + currentImage.image.image : currentImage.image.image_url}
          alt={"preview"}
          width={80}
          height={80}
          style={{ borderRadius: 8, cursor: "pointer" }}
          onClick={open}
        />
      </div>

      <Modal opened={opened} onClose={close} title={formFieldName} size="auto">
        <Group wrap="nowrap" mt="md">
          <img
            src={currentImage.image.image ? apiRoot + currentImage.image.image : currentImage.image.image_url}
            alt={"preview"}
            width={450}
            height={500}
            style={{ borderRadius: 8 }}
          />
        </Group>
      </Modal>
    </>
  );
}

export default ImageComponent;
