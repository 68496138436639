import { apiGet, apiPost } from "acds-react-core";
import _ from "lodash";
import { toast } from "react-toastify";
import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import toastr from "toastr";
import { ACDS_CONSTANTS } from "../../constants/constants";
import {
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_BRANDS_NEXT,
  GET_PRODUCT_BRANDS_NEXT_SUCCESS,
  GET_PRODUCT_BRANDS_SUCCESS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_ERRORS,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_MANUFACTURERS,
  GET_PRODUCT_MANUFACTURERS_NEXT,
  GET_PRODUCT_MANUFACTURERS_NEXT_SUCCESS,
  GET_PRODUCT_MANUFACTURERS_SUCCESS,
  GET_UNSAFE_INGREDIENTS,
  POST_REPORT_PRODUCT,
  POST_REPORT_PRODUCT_ERRORS,
  POST_REPORT_PRODUCT_SUCCESS,
  POST_REVIEW_PRODUCT,
  POST_REVIEW_PRODUCT_ERRORS,
  POST_REVIEW_PRODUCT_SUCCESS,
  POST_SUBMIT_PRODUCT,
  POST_SUBMIT_PRODUCT_ERRORS,
  POST_SUBMIT_PRODUCT_SUCCESS,
  SET_ADD_PRODUCT_SUBMITTING,
  SET_UNSAFE_INGREDIENTS,
  SET_UNSAFE_INGREDIENTS_LOADING,
  UPLOAD_PRODUCT_PHOTO,
  UPLOAD_PRODUCT_PHOTO_ERRORS,
  UPLOAD_PRODUCT_PHOTO_SUCCESS,
} from "../actions/safeList";

function* doGetProductCategories() {
  try {
    const response = yield apiGet("api/products/categories/", null, false);

    yield put({
      type: GET_PRODUCT_CATEGORIES_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: GET_PRODUCT_CATEGORIES_ERRORS,
      error: error.response.data,
    });
  }
}

function* doReviewProduct(action) {
  const { params } = action;

  const reviewParms = {
    product: params.productId,
    content: _.get(params, "reviewProduct.comments", null),
    rating: params.starCount,
    is_anon: _.get(params, "reviewProduct.anon", false),
    userName: _.get(params, "userName"),
  };

  try {
    const response = yield apiPost("api/products/reviews/", reviewParms);

    yield put({
      type: POST_REVIEW_PRODUCT_SUCCESS,
      response,
    });

    toast.success("Product Review Submitted", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
    });

    params.history.goBack();
  } catch (error) {
    yield put({
      type: POST_REVIEW_PRODUCT_ERRORS,
      error: error.message,
    });

    toastr.options.positionClass = "toast-bottom-right";
    toastr.error("Product Review Error");
  }
}

function* doReportProduct(action) {
  const { params, history } = action;
  try {
    const response = yield apiPost("api/products/report/", params);

    yield put({
      type: POST_REPORT_PRODUCT_SUCCESS,
      response,
    });

    history.push("/report-product-confirmation");
  } catch (error) {
    yield put({
      type: POST_REPORT_PRODUCT_ERRORS,
      error: error.message,
    });

    toastr.options.positionClass = "toast-bottom-right";
    toastr.error("Product Report Error");
  }
}

function* doSubmitProduct({ params }) {
  try {
    yield put({
      type: SET_ADD_PRODUCT_SUBMITTING,
      value: true,
    });
    const product = _.pick(params.product, ["name", "comments", "brand", "description", "manufacturer", "category"]);
    const response = yield apiPost("api/products/", {
      data: {
        ...product,
      },
    });

    const fileListPick = _.pickBy(params.product, (p) => _.get(p, "0.name"));
    // Positions associated with position numbers
    const imagePositions = ["front", "back", "ingredient", "expiration"];
    // Create collection of image name and position objects
    const fileNameAndPosition = _.flatMapDeep(fileListPick, (value, key) => {
      let position = 0;
      if (_.indexOf(imagePositions, key) !== -1) {
        position = _.indexOf(imagePositions, key);
      }

      return _.map(value, (v) => {
        return { name: v.name, position };
      });
    });

    // Create array of files only for mapping purposes
    const fileList = _.flattenDeep(_.map(fileListPick, (pick) => _.values(pick)));

    // Upload photos one at a time
    yield all(
      _.map(fileList, (photo) => {
        return put({
          type: UPLOAD_PRODUCT_PHOTO,
          photo,
          productId: response.id,
          position: _.get(
            _.find(fileNameAndPosition, (value) => value.name === photo.name),
            "position",
          ),
        });
      }),
    );

    yield put({
      type: POST_SUBMIT_PRODUCT_SUCCESS,
      response,
    });

    yield put({
      type: SET_ADD_PRODUCT_SUBMITTING,
      value: false,
    });

    if (_.get(params, "isPhysician", false)) {
      params.formReset();
    } else {
      params.history.push("/safe-list");
    }

    toast.success("Your product suggestion is successfully submitted", {
      bodyClassName: "toast-body",
      style: {
        backgroundColor: "#414B4F",
        color: "#ffffff",
      },
      progressStyle: {
        background: "#00c9b7",
      },
      closeButton: true,
    });
  } catch (error) {
    yield put({
      type: SET_ADD_PRODUCT_SUBMITTING,
      value: false,
    });

    yield put({
      type: POST_SUBMIT_PRODUCT_ERRORS,
      error: error.response.data,
    });

    toastr.options.positionClass = "toast-bottom-right";
    toastr.error("Product Submission Error");
  }
}

function* doProductImageUpload({ photo, productId, position }) {
  const formData = new FormData();

  formData.append("product", productId);
  formData.append("position", position);
  formData.append("image", photo);

  try {
    const response = yield apiPost("api/products/upload/", {
      data: formData,
      headers: { "Content-type": "multipart/form-data" },
    });

    yield put({
      type: UPLOAD_PRODUCT_PHOTO_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: UPLOAD_PRODUCT_PHOTO_ERRORS,
      error: error.response.data,
    });
  }
}

function* doGetUnsafeIngredients({ code1, code2 }) {
  const apiString = `api/ingredients/quick-list/?unsafe=true&code1=${code1}&code2=${code2}`;
  const isLoading = true;
  try {
    yield put({
      type: SET_UNSAFE_INGREDIENTS_LOADING,
      payload: isLoading,
    });

    const response = yield apiGet(apiString);
    yield put({
      type: SET_UNSAFE_INGREDIENTS,
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
}

function* doGetProductBrands({ params }) {
  const apiRoot = yield select((state) => state.env.api_root);
  const searchValue = _.get(params, "searchValue", "");
  const apiString = "api/search/brand/";
  const url = new URL(apiString, apiRoot);

  if (searchValue.length > 0) {
    url.searchParams.append("name", searchValue);
  }

  url.searchParams.append("sort_by", "name");
  url.searchParams.append("fuzzy", true);

  try {
    const response = yield apiGet(_.replace(url, apiRoot + "/", ""));
    const data = _.map(response.results, (obj) => _.pick(_.get(obj, "_source"), ["id", "name"]));
    yield put({
      type: GET_PRODUCT_BRANDS_SUCCESS,
      response: {
        data,
        next: response.next,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductBrandsNext({ searchValue }) {
  const apiRoot = yield select((state) => state.env.api_root);
  const productBrands = yield select((state) => state.safeList.productBrands);

  const apiString = "api/search/brand";
  const url = new URL(apiString, apiRoot);

  url.searchParams.append("offset", productBrands?.length);
  url.searchParams.append("limit", ACDS_CONSTANTS.PRODUCT_BRAND_LIMIT);
  url.searchParams.append("sort_by", "name");
  url.searchParams.append("fuzzy", true);

  if (searchValue.length > 0) {
    url.searchParams.append("name", searchValue);
  }

  try {
    const response = yield apiGet(_.replace(url, apiRoot + "/", ""));
    const data = _.map(response.results, (obj) => _.pick(_.get(obj, "_source"), ["id", "name"]));

    yield put({
      type: GET_PRODUCT_BRANDS_NEXT_SUCCESS,
      response: {
        data,
      },
    });
  } catch (err) {
    console.error(err);
  }
}

function* doGetProductManufactures({ params }) {
  const apiRoot = yield select((state) => state.env.api_root);
  const searchValue = _.get(params, "searchValue", "");
  const apiString = "api/search/manufacturer/";
  const url = new URL(apiString, apiRoot);

  if (searchValue?.length > 0) {
    url.searchParams.append("name", searchValue);
  }

  url.searchParams.append("sort_by", "name");
  url.searchParams.append("fuzzy", true);

  try {
    const response = yield apiGet(_.replace(url, apiRoot + "/", ""));
    const data = _.map(response.results, (obj) => _.pick(_.get(obj, "_source"), ["id", "name"]));
    yield put({
      type: GET_PRODUCT_MANUFACTURERS_SUCCESS,
      response: {
        data,
        next: response.next,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function* doGetProductManufacturesNext({ searchValue }) {
  const apiRoot = yield select((state) => state.env.api_root);
  const manufactures = yield select((state) => state.safeList.productManufactures);

  const apiString = "api/search/manufacturer";
  const url = new URL(apiString, apiRoot);

  if (searchValue?.length > 0) {
    url.searchParams.append("name", searchValue);
  }

  url.searchParams.append("offset", manufactures?.length);
  url.searchParams.append("limit", ACDS_CONSTANTS.PRODUCT_MANUFACTURER_LIMIT);
  url.searchParams.append("sort_by", "name");
  url.searchParams.append("fuzzy", true);

  try {
    const response = yield apiGet(_.replace(url, apiRoot + "/", ""));
    const data = _.map(response.results, (obj) => _.pick(_.get(obj, "_source"), ["id", "name"]));

    yield put({
      type: GET_PRODUCT_MANUFACTURERS_NEXT_SUCCESS,
      response: {
        data,
      },
    });
  } catch (err) {
    console.error(err);
  }
}

export default function* safeListSagas() {
  yield takeLatest(GET_PRODUCT_CATEGORIES, doGetProductCategories);
  yield takeLatest(POST_REVIEW_PRODUCT, doReviewProduct);
  yield takeLatest(POST_REPORT_PRODUCT, doReportProduct);
  yield takeLatest(POST_SUBMIT_PRODUCT, doSubmitProduct);
  yield takeEvery(UPLOAD_PRODUCT_PHOTO, doProductImageUpload);
  yield takeLatest(GET_UNSAFE_INGREDIENTS, doGetUnsafeIngredients);
  yield takeLatest(GET_PRODUCT_BRANDS, doGetProductBrands);
  yield takeLatest(GET_PRODUCT_BRANDS_NEXT, doGetProductBrandsNext);
  yield takeLatest(GET_PRODUCT_MANUFACTURERS, doGetProductManufactures);
  yield takeLatest(GET_PRODUCT_MANUFACTURERS_NEXT, doGetProductManufacturesNext);
}
