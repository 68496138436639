import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faAngleDown, faCamera, faEye, faEyeSlash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import * as _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";

export function CheckBox(props) {
  const { errors, register } = useFormContext();

  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <Input
        name={props.name}
        type="checkbox"
        onChange={props.onChange}
        innerRef={register({
          required: props.requiredField && "Required",
        })}
        invalid={!!_.get(errors, props.name)}
      />
      <FormFeedback role="alert">{_.get(errors, `${props.name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

export function TextInput(props) {
  const { errors, register } = useFormContext();

  const keyDown = (e) => {
    if (props.preventEnter && e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <FormGroup
      className={classnames({
        "form-group-with-icon": props.icon,
      })}
    >
      {props.icon ? (
        <FontAwesomeIcon className="font-awesome-icon" icon={props.icon} size="lg" />
      ) : (
        <div>
          <Label for={props.name}>{props.label}</Label>
        </div>
      )}
      <Input
        defaultValue={props.defaultValue || null}
        disabled={props.disabled}
        name={props.name}
        placeholder={props.placeholder}
        type="text"
        innerRef={register({
          required: props.requiredField && "This field is required",
        })}
        invalid={!!_.get(errors, props.name)}
        onKeyDown={keyDown}
      />
      <FormFeedback role="alert">{_.get(errors, `${props.name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

export function MultiLineInput(props) {
  const { errors, register } = useFormContext();

  return (
    <FormGroup>
      <div>
        <Label for={props.name} className="product-report-text float-start submit-product-label">
          {props.label}
        </Label>
        <div className="submit-product-type">
          <span className="mx-2">{props.requiredField ? "  (Required)" : "(Optional)"}</span>
        </div>
      </div>
      <Input
        className="input-textarea"
        defaultValue={props.defaultValue || null}
        name={props.name}
        placeholder={props.placeholder}
        type="textarea"
        onChange={props.onChange}
        rows={props.rows}
        innerRef={register({
          maxLength: {
            value: 250,
            message: "Cannot exceed 250 characters.",
          },
          required: props.requiredField && "This field is required",
        })}
        invalid={!!_.get(errors, props.name)}
        maxLength={250}
      />
      <FormFeedback role="alert">{_.get(errors, `${props.name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

// props include 'optionsList', 'name', 'label', 'defaultOption', 'defaultDisplay', and 'id'
export function DropdownInput(props) {
  const { errors, register, setValue, unregister } = useFormContext();
  const idString = _.toString(props.id);

  useEffect(() => {
    register({ name: idString, id: props.id, type: "custom" });

    return () => unregister(idString);
  }, [register, unregister, props, idString]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(
    !_.isEmpty(props.defaultOption) ? props.defaultOption : { name: props.defaultDisplay || "Choose an option" },
  );

  useEffect(() => {}, [dropdownValue]);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const chooseOption = (option) => {
    props.setIsReset(false);
    setDropdownValue({ ...option, existing: props.defaultOption });
    setValue(idString, { ...option, existing: props.defaultOption });
  };

  useEffect(() => {
    if (_.has(props.defaultOption, "id")) {
      chooseOption({ ...props.defaultOption, question: props.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props.isReset) {
      chooseOption({ ...props.defaultOption, question: props.id });
    }
  }, [props.isReset]);

  const optionsList = _.map(props.optionsList, (option) => {
    const optionValue = { ...option, question: props.id };
    const isDisabled = optionValue.id === "1-choose-an-option";

    return (
      <DropdownItem key={option.id} value={optionValue} onClick={() => chooseOption(optionValue)} disabled={isDisabled}>
        {option.name}
      </DropdownItem>
    );
  });

  return (
    <FormGroup className="dropdown-input">
      <Label className="toggle-question">{props.label}</Label>
      <Dropdown
        name={props.name}
        isOpen={dropdownOpen}
        className="gap-5"
        toggle={toggle}
        placeholder="choose an option"
      >
        <DropdownToggle className="toggle-dropdown">
          <span className="dropdown-toggle-selection">{dropdownValue.name}</span>
          <FontAwesomeIcon className="font-awesome-input" icon={faAngleDown} size="lg" />
        </DropdownToggle>
        <DropdownMenu ref={register}>{optionsList}</DropdownMenu>
      </Dropdown>
      <FormFeedback role="alert">{_.get(errors, `${props.name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

// props include 'optionsList', 'name', 'label', 'defaultOption', 'defaultDisplay', and 'id'
export function FormSelectInput({
  optionsList,
  label,
  name,
  defaultOption,
  placeholder,
  optionLabel,
  optionValue,
  id,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
}) {
  const { errors, register, setValue, unregister } = useFormContext();
  const idString = _.toString(id);

  useEffect(() => {
    register({ name: idString, id, type: "custom" });

    return () => unregister(idString);
  }, [id, idString, register, unregister]);

  const defaultSelectedOptionValue = _.isEmpty(defaultOption)
    ? null
    : _.find(optionsList, ["id", _.get(defaultOption, "answer_choices.0.id", null)]);

  useLayoutEffect(() => {}, [defaultOption, defaultSelectedOptionValue]);

  const handleOnSelect = (value) => {
    setValue(idString, { ...value, question: id, existing: { ...defaultOption, name: value.optionLabel } });
  };

  return (
    <FormGroup className="dropdown-input" key={`${id}-parent`}>
      <Label className="toggle-question" style={{ marginRight: 30 }}>
        {label}
      </Label>
      <Select
        defaultValue={_.isEmpty(defaultOption) ? null : defaultSelectedOptionValue}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionValue={(option) => option[optionValue]}
        options={optionsList}
        onChange={handleOnSelect}
        key={`${id}-select`}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minWidth: "200px",
            marginLeft: 20,
            marginRight: 20,
          }),
        }}
      />
      <FormFeedback role="alert">{_.get(errors, `${name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

export function CheckboxInput(props) {
  const { register } = useFormContext();
  const [buttonClass, setButtonClass] = useState("btn-outlined");
  const [sull, setSull] = useState(true);

  const toggle = () => {
    props.onChange({
      id: props.id,
      isChecked: !props.isChecked,
      name: props.name,
    });
  };

  const setButton = () => {
    setButtonClass("btn-filled");
  };

  return (
    <FormGroup>
      <div className="d-flex gap-2">
        <Input
          name={props.name}
          checked={props.isChecked}
          type="checkbox"
          ref={register}
          onChange={toggle}
          value={props.label}
          htmlFor={props.name}
          onClick={() => setButton()}
        />
        <Label className="btn-outlined" htmlFor={props.name}>
          {props.label}
        </Label>
      </div>
    </FormGroup>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CheckboxInput.defaultProps = {
  name: "",
  label: "",
  isChecked: false,
  id: "",
};

export function MultiCheckBoxInput(props) {
  const { register, setValue, unregister } = useFormContext();

  const [multiChecked, setMultiChecked] = useState(props.defaultValues);
  const idString = _.toString(props.id);

  useEffect(() => {
    // Register a custom component
    register({
      name: idString,
      id: props.id,
      type: "custom",
    });

    // Remove registration when this form unmounts
    return () => unregister(idString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idString]);

  useEffect(() => {
    setMultiChecked(props.defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValues]);

  const setMulti = useCallback(() => {
    // Set the value to make it available within the parent component
    setValue(idString, {
      name: props.name,
      question: props.id,
      questionType: 2,
      answer_choices: multiChecked,
      existing: props.defaultValues,
      id: _.get(props, "fullUserAnswer.0.id", props.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiChecked]);

  useEffect(() => {
    setMulti();
  }, [multiChecked, setMulti]);

  useEffect(() => {
    if (props.isReset) {
      setMultiChecked(props.defaultValues);
    }
  }, [props.isReset]);

  const updateMulti = (option) => {
    // Create array of answers if none exist
    if (!_.isArray(multiChecked)) {
      setMultiChecked([option]);
      // Add new selection
    } else if (option.isChecked) {
      setMultiChecked([...multiChecked, option]);
      // Remove existing selection
    } else {
      setMultiChecked(_.filter(multiChecked, (multi) => multi.id !== option.id));
    }
    props.setIsReset(false);
  };

  const checkBoxes = _.map(props.optionsList, (option) => {
    const isChecked = !!_.find(multiChecked, (opt) => opt.id === option.id);
    const isDefault = !!_.find(props.defaultValues, (opt) => opt.id === option.id);
    let className = "btn-outlined mx-4 my-2";
    if (!props.isReset) {
      className = isChecked ? "btn-filled mx-4 my-2" : "btn-outlined mx-4 my-2";
    } else {
      className = isDefault ? "btn-filled mx-4 my-2" : "btn-outlined mx-4 my-2";
    }

    return (
      <Row className="d-block" key={option.id}>
        <Button
          name={option.name}
          id={option.id}
          className={className}
          onClick={() => {
            updateMulti({
              id: option.id,
              isChecked: !isChecked,
              name: option.name,
            });
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {option.name}
          </span>
        </Button>
      </Row>
    );
  });

  return (
    <FormGroup className="multi-checkbox-input">
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <Col xs={4} className="toggle-question">
          <Row style={{ marginRight: 20, width: 225, flexGrow: 0, paddingLeft: 10 }}>{props.label}</Row>
          <Row style={{ paddingLeft: 10 }}>
            <span style={{ color: "#626769", fontWeight: 100, maxWidth: 185, fontSize: 14 }}>
              <i>Note: You can select one or more options.</i>
            </span>
          </Row>
        </Col>
        <Col className="d-inline-flex flex-wrap ml-2">{checkBoxes}</Col>
        <Col></Col>
      </Row>
    </FormGroup>
  );
}

MultiCheckBoxInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValues: PropTypes.array,
  optionsList: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MultiCheckBoxInput.defaultProps = {
  name: "",
  label: "",
  defaultValues: [],
  optionsList: [],
  id: "",
};

// Account Specific Inputs
export function EmailInput(props) {
  const { errors, register } = useFormContext();

  return (
    <FormGroup
      className={classnames({
        "form-group-with-icon": props.icon,
        "form-group-without-left-icon": !props.icon,
      })}
    >
      <Input
        innerRef={register({
          required: "This is a required field",
          pattern: {
            value: /^[A-Za-z0-9._%+#-]+@[A-Za-z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address",
          },
        })}
        invalid={!!_.get(errors, "email.message")}
        name="email"
        defaultValue={props.defaultValue}
        placeholder="Email ID"
        type="email"
        className="camp-input-1"
      />
      <Button className="btn icon-btn password--eye-icon pointer-event-none" onClick={() => {}}>
        <FontAwesomeIcon className="font-awesome-icon" icon={props.icon} size="lg" />
      </Button>
      <FormFeedback role="alert">{_.get(errors, "email.message")}</FormFeedback>
    </FormGroup>
  );
}

export function Password(props) {
  const { errors, register } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Row>
      <Col>
        <FormGroup
          className={classnames({
            "form-group-with-icon": props.icon,
            "form-group-without-left-icon": !props.icon,
          })}
        >
          <Input
            innerRef={register({
              required: "This field is required.",
            })}
            invalid={!!_.get(errors, "password")}
            name="password"
            placeholder="Password"
            type={isVisible ? "text" : "password"}
            className="camp-input-1"
          />
          <Button
            className="btn icon-btn password--eye-icon"
            onClick={() => {
              setIsVisible((prev) => !prev);
            }}
          >
            {isVisible ? (
              <FontAwesomeIcon className="font-awesome-icon" icon={props.hideIcon} size="lg" />
            ) : (
              <FontAwesomeIcon className="font-awesome-icon" icon={props.visibleIcon} size="lg" />
            )}
          </Button>
          <FormFeedback role="alert">{_.get(errors, "password.message")}</FormFeedback>
        </FormGroup>
      </Col>
    </Row>
  );
}

export function UsernameInput(props) {
  const { errors, register } = useFormContext();

  return (
    <FormGroup>
      <Label for="username" className="label-name">
        Public Profile Name &#40;Identifier used during product reviews&#41;
      </Label>
      <Input
        innerRef={register({
          pattern: {
            value: /^[A-Z0-9._@+-]+$/i,
            message: "Profile name can contain letters, numbers, and these characters: @.+-_ (no spaces allowed)",
          },
          required: true,
        })}
        defaultValue={props.defaultValue || null}
        invalid={!!_.get(errors, "username")}
        name="username"
        placeholder="Public Profile Name"
        type="text"
      />
      <FormFeedback role="alert">{_.get(errors, "username.message")}</FormFeedback>
    </FormGroup>
  );
}

export function PasswordOneInput() {
  const { errors, register } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);

  return (
    <FormGroup className="form-group-without-left-icon ">
      <Label for="password1" className="password-label">
        New Password
      </Label>
      <Input
        className="password-input"
        innerRef={register({
          minLength: {
            value: 8,
            message: "Password must be 8 characters or more.",
          },
          pattern: {
            // Confirm that the password contains alphanumeric characters plus at least one symbol
            value: /^.*(?=.*[\w]+)(?=.*[\d]+)(?=.*[!@#$%^&*()+?]).*$/i,
            message: "Passwords must contain one letter, one number, and one special character",
          },
          required: true,
        })}
        invalid={!!_.get(errors, "password1")}
        name="password1"
        placeholder="Password"
        type={isVisible ? "text" : "password"}
      />
      <FormFeedback role="alert">{_.get(errors, "password1.message")}</FormFeedback>
      <Button className="btn icon-btn password--eye-icon" onClick={() => setIsVisible(!isVisible)}>
        <FontAwesomeIcon
          className="font-awesome-icon"
          icon={isVisible ? faEye : faEyeSlash}
          color="#00171f"
          size="lg"
        />
      </Button>
    </FormGroup>
  );
}

export function ConfirmPassword() {
  const { errors, register, watch } = useFormContext();

  const [isVisible, setIsVisible] = useState(false);

  return (
    <FormGroup className="form-group-without-left-icon ">
      <Label for="password2" className="password-label">
        Confirm Password
      </Label>
      <Input
        innerRef={register({
          required: "This field is required.",
          validate: (value) => value === watch("password1") || "Passwords do not match.",
        })}
        invalid={!!_.get(errors, "password2")}
        name="password2"
        placeholder="Confirm Password"
        type={isVisible ? "text" : "password"}
        className="password-input "
      />
      <FormFeedback role="alert">{_.get(errors, "password2.message")}</FormFeedback>
      <Button className="btn icon-btn password--eye-icon" onClick={() => setIsVisible(!isVisible)}>
        <FontAwesomeIcon
          className="font-awesome-icon"
          color="#00171f"
          icon={isVisible ? faEye : faEyeSlash}
          size="lg"
        />
      </Button>
    </FormGroup>
  );
}

export function PhotoUpload({ name, label, requiredField = false }) {
  const { control, errors, register, setValue } = useFormContext();
  const [firstFileName, setFirstFileName] = useState("None Selected");

  const addPhotos = (e) => {
    const files = e.target.files;
    setFirstFileName(_.get(files, "0.name"));
    setValue("file", files);
  };

  return (
    <FormGroup>
      <Label>{label}</Label>
      <InputGroup
        className={classnames("photo-upload", {
          "is-invalid": !!_.get(errors, name),
        })}
      >
        <InputGroup>
          <InputGroupText>
            <FontAwesomeIcon className="font-awesome-icon" icon={faCamera} />
            <Input
              disabled
              placeholder={firstFileName}
              className="col-7 upload-input"
              invalid={!!_.get(errors, name)}
            />
          </InputGroupText>
        </InputGroup>
        <InputGroup>
          <InputGroupText addonType="append" className="col-4 form-control upload-button">
            <Input
              className="file-input"
              control={control}
              type="file"
              id={name}
              invalid={!!_.get(errors, name)}
              name={name}
              accept="image/png, image/jpeg"
              multiple
              innerRef={register({
                required: requiredField && "This field is required",
              })}
              onChange={addPhotos}
            />
            <Label for={name}>Select Photo</Label>
          </InputGroupText>
        </InputGroup>
      </InputGroup>
      <FormFeedback role="alert">{_.get(errors, `${name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

export function SwitchInput(props) {
  const { errors, register } = useFormContext();
  const [checked, setChecked] = React.useState(props.defaultValue || false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onChange && props.onChange(event.target.checked);
  };

  return (
    <FormGroup switch>
      <div className="row card-notification m-3">
        <div className="col px-0">
          <h2 className="header-notification">{props.header}</h2>
        </div>
        <div className="row">
          <Label className="summary-notification">{props.label}</Label>
          <Input
            id={props.id}
            name={props.name}
            type="switch"
            role={"switch"}
            className="form-input-end"
            checked={checked}
            onChange={handleChange}
            defaultChecked={props.defaultValue}
            innerRef={register({
              required: props.requiredField && "Required",
            })}
            invalid={!!_.get(errors, props.name)}
          />
        </div>
      </div>
    </FormGroup>
  );
}

SwitchInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SwitchInput.defaultProps = {
  name: "",
  label: "",
  isChecked: false,
  onChange: () => null,
  id: "",
};

export function RadioGroup(props) {
  const { register } = useFormContext();
  const handleRadioSelect = (question, event) => {
    props.onRadioClick(question, event.target.value);
  };

  return (
    <FormGroup className="radio-group--fieldset" tag="fieldset">
      {_.map(props.options, (option, key) => (
        <FormGroup key={option} check>
          <Col>
            <Row className="radio-input--row justify-content-center">
              <Input
                className="radio-input"
                checked={props.qolSelectedRatings[props.name] == option}
                type="radio"
                name={props.name}
                innerRef={register}
                id={option}
                value={option}
                required={true}
                onChange={(event) => handleRadioSelect(props.name, event)}
              />
            </Row>
            <Row>
              <Label className="d-flex justify-content-center" check>
                {option}
              </Label>
            </Row>
          </Col>
        </FormGroup>
      ))}
    </FormGroup>
  );
}

export function TextInputSubmitProduct(props) {
  const { errors, register } = useFormContext();
  const keyDown = (e) => {
    if (props.preventEnter && e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <FormGroup>
      <div>
        <Label for={props.name} className="float-start submit-product-label">
          {props.label}
        </Label>
        <div className="float-end submit-product-type">{props.requiredField ? "(Required)" : "(Optional)"}</div>
      </div>

      <Input
        defaultValue={props.defaultValue || null}
        disabled={props.disabled}
        name={props.name}
        placeholder={props.placeholder}
        type="text"
        innerRef={register({
          required: props.requiredField && `${props.label} is required`,
        })}
        invalid={!!_.get(errors, props.name)}
        onKeyDown={keyDown}
      />
      <FormFeedback>{_.get(errors, `${props.name}.message`)}</FormFeedback>
    </FormGroup>
  );
}

export function DropdownInputSubmitProduct({ name, optionsList, label, requiredField, placeholder, id }) {
  const { errors, control } = useFormContext();

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      const viewportHeight = window.innerHeight;
      const scrollPosition = element.top - viewportHeight / 2 + element.height / 2;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [_.get(errors, `${name}.types.message`)]);

  return (
    <FormGroup
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Label for={name} className="float-start submit-product-label">
          {label}
        </Label>
        <div className="float-end submit-product-type">{requiredField ? "(Required)" : "(Optional)"}</div>
      </div>
      <Controller
        name={name}
        control={control}
        options={optionsList}
        mode="onChange"
        rules={[
          {
            required: requiredField,
          },
        ]}
        as={({ name, onChange, options, value }) => {
          return (
            <Select
              isSearchable
              isClearable
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              name={name}
              onChange={onChange}
              options={options}
              value={value}
              placeholder={placeholder}
            />
          );
        }}
      />
      {_.get(errors, `${name}.types.message`) && (
        <div id={id}>
          <span className="submit-product-error-message">{_.get(errors, `${name}.types.message`)}</span>
        </div>
      )}
    </FormGroup>
  );
}

export function ProductDropDown({
  name,
  handleNext,
  label,
  optionsList,
  requiredField,
  placeholder,
  isSearchable,
  isClearable,
  setValue,
  onSearch,
  fieldRef,
  isLoading,
}) {
  const { errors, control } = useFormContext();
  const dispatch = useDispatch();
  const onHandleNext = () => {
    if (handleNext !== undefined) {
      dispatch(handleNext());
    }
  };

  return (
    <FormGroup
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Label for={name} className="float-start submit-product-label">
          {label}
        </Label>
        <div className="float-end submit-product-type">{requiredField ? "(Required)" : "(Optional)"}</div>
      </div>
      <div>
        <Select
          isLoading={isLoading}
          isClearable
          isSearchable={isSearchable}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={placeholder}
          options={optionsList}
          name={name}
          onChange={(value) => {
            setValue(value);
          }}
          onMenuScrollToBottom={handleNext}
          onInputChange={onSearch}
          filterOption={null}
          ref={fieldRef}
        />
      </div>
      <div>
        <FormFeedback role="alert">{_.get(errors, `${name}.message`)}</FormFeedback>
      </div>
    </FormGroup>
  );
}

export function MyProfileCurrentPasswordField() {
  const name = "currentPassword";
  const { errors, register } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);
  const inputGroupTextContainerStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #e7edef",
    borderLeft: "none",
    cursor: "pointer",
  };

  const inputGroupTextContainerError = {
    backgroundColor: "#ffffff",
    border: "1px solid #e7edef",
    borderLeft: "red",
    cursor: "pointer",
  };

  const hasErrors = _.isEmpty(_.get(errors, `${name}.message`));

  return (
    <FormGroup className="profile-page-form-group  m-2 w-md-75">
      <Label for={name} className="password-label">
        Current Password
      </Label>
      <InputGroup style={{ maxWidth: 600 }}>
        <Input
          className="password-input"
          innerRef={register({
            minLength: {
              value: 8,
              message: "Password must be 8 characters or more.",
            },
            pattern: {
              // Confirm that the password contains alphanumeric characters plus at least one symbol
              value: /^.*(?=.*[\w]+)(?=.*[\d]+)(?=.*[!@#$%^&*()+?]).*$/i,
              message: "Passwords must contain one letter, one number, and one special character",
            },
            required: true,
          })}
          invalid={!!_.get(errors, name)}
          name={name}
          placeholder={"Current Password"}
          type={isVisible ? "text" : "password"}
          style={{ paddingInline: 10 }}
        />

        <InputGroupText
          style={hasErrors ? inputGroupTextContainerError : inputGroupTextContainerStyle}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          <FontAwesomeIcon
            icon={isVisible ? faEye : faEyeSlash}
            color="#00171f"
            size="lg"
            style={{
              cursor: "pointer",
            }}
          />
        </InputGroupText>
        <FormFeedback role="alert" name>
          {_.get(errors, `${name}.message`)}
        </FormFeedback>
      </InputGroup>
    </FormGroup>
  );
}

export function MyProfileNewPasswordField() {
  const name = "newPassword";
  const { errors, register } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);
  const inputGroupTextContainerStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #e7edef",
    borderLeft: "none",
    cursor: "pointer",
  };

  const inputGroupTextContainerError = {
    backgroundColor: "#ffffff",
    border: "1px solid #e7edef",
    borderLeft: "red",
    cursor: "pointer",
  };

  const hasErrors = _.isEmpty(_.get(errors, `${name}.message`));

  return (
    <FormGroup className="profile-page-form-group  m-2 w-md-75">
      <Label for={"new_password"} className="password-label">
        New Password
      </Label>
      <InputGroup style={{ maxWidth: 600 }}>
        <Input
          className="password-input"
          innerRef={register({
            minLength: {
              value: 8,
              message: "Password must be 8 characters or more.",
            },
            pattern: {
              // Confirm that the password contains alphanumeric characters plus at least one symbol
              value: /^.*(?=.*[\w]+)(?=.*[\d]+)(?=.*[!@#$%^&*()+?]).*$/i,
              message: "Passwords must contain one letter, one number, and one special character",
            },
            required: true,
          })}
          invalid={!!_.get(errors, name)}
          name={name}
          placeholder={"New Password"}
          type={isVisible ? "text" : "password"}
          style={{ paddingInline: 10 }}
        />

        <InputGroupText
          style={hasErrors ? inputGroupTextContainerError : inputGroupTextContainerStyle}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          <FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} color="#00171f" size="lg" />
        </InputGroupText>
        <FormFeedback role="alert" name>
          {_.get(errors, `${name}.message`)}
        </FormFeedback>
      </InputGroup>
    </FormGroup>
  );
}

export function MyProfileConfirmNewPasswordField() {
  const name = "confirmNewPassword";
  const { errors, register, watch } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);
  const inputGroupTextContainerStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #e7edef",
    borderLeft: "none",
    cursor: "pointer",
  };

  const inputGroupTextContainerError = {
    backgroundColor: "#ffffff",
    border: "1px solid #e7edef",
    borderLeft: "red",
    cursor: "pointer",
  };

  const hasErrors = _.isEmpty(_.get(errors, `${name}.message`));

  return (
    <FormGroup className="profile-page-form-group  m-2 w-md-75">
      <Label for={name} className="password-label">
        Confirm New Password
      </Label>
      <InputGroup style={{ maxWidth: 600 }}>
        <Input
          className="password-input"
          innerRef={register({
            required: {
              value: true,
              message: "This is a required field",
            },
            validate: (value) => value === watch("newPassword") || "Passwords do not match.",
          })}
          invalid={!!_.get(errors, name)}
          name={name}
          placeholder={"Confirm New Password"}
          type={isVisible ? "text" : "password"}
          disabled={!!_.get(errors, "newPassword") || _.isEmpty(watch("newPassword"))}
          style={{ paddingInline: 10 }}
        />

        <InputGroupText
          style={hasErrors ? inputGroupTextContainerError : inputGroupTextContainerStyle}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          <FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} color="#00171f" size="lg" />
        </InputGroupText>
        <FormFeedback role="alert" name>
          {_.get(errors, `${name}.message`)}
        </FormFeedback>
      </InputGroup>
    </FormGroup>
  );
}

export function SubmitProductImage({ name, label, required }) {
  const { errors, register, watch, setValue } = useFormContext();
  const productImage = watch(name);

  useEffect(() => {}, [errors]);

  const errorMessage = _.get(errors, `${name}.message`);

  const validateFileSize = (file) => {
    const maxSize = 500 * 1024; // 500 kb

    return (file && file[0].size <= maxSize) || "File size should not exceed 500 KB.";
  };

  return (
    <FormGroup>
      <Input
        className="d-none"
        name={name}
        id={name}
        type="file"
        accept=".jpeg,.png"
        innerRef={register({
          required: required && `${label} is required`,
          validate: validateFileSize,
        })}
        maxLength={1}
      />
      <div className="text-center p-2">
        <span className="submit-product-image-label">{label}</span>
        {required && (
          <span
            className="m-1"
            style={{
              color: "#eb5757",
            }}
          >
            *
          </span>
        )}
      </div>
      <div className={errorMessage ? "add-product-image-container--invalid" : "add-product-image-container"}>
        {!_.isEmpty(productImage) ? (
          <>
            <img src={URL.createObjectURL(productImage[0])} alt="preview" className="add-product-image" />
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="add-product-clear-icon"
              size="1x"
              onClick={() => {
                const emptyFile = new DataTransfer().files;
                setValue(name, emptyFile);
              }}
            />
          </>
        ) : (
          <FontAwesomeIcon
            icon={"camera"}
            size="3x"
            color="#595e60"
            className="font-awesome-icon"
            onClick={() => {
              document.getElementById(name).click();
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "11rem",
          margin: "5px",
        }}
      >
        {errorMessage && (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              color: "#f11646",
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </FormGroup>
  );
}

export function SubmitProductAdditionalImage1() {
  const name = "additionalProduct1";
  const { register, watch, setValue, errors, clearError } = useFormContext();
  const productImage = watch(name);
  const additionProductImage2 = watch("additionalProduct2");
  const label = "Additional Product 1";
  const emptyMessage = "Add 2 more photos";

  const validateFileSize = (file) => {
    const maxSize = 500 * 1024; // 500 kb

    if (file && file[0] && file[0].size > maxSize) {
      return "File size should not exceed 500 KB.";
    }

    return true;
  };

  const errorMessage = _.get(errors, `${name}.message`);
  useEffect(() => {}, [errors]);

  return (
    <div>
      <Input
        className="d-none"
        name={name}
        id={name}
        type="file"
        accept=".jpeg,.png"
        innerRef={register({
          validate: validateFileSize,
          required: false,
        })}
        maxLength={1}
      />
      <div className="text-center p-2">
        <span className="submit-product-image-label">{productImage?.length ? label : emptyMessage}</span>
      </div>
      <div className={productImage?.length ? "add-product-image-container" : "add-product-image-container--dashed"}>
        {!_.isEmpty(productImage) ? (
          <>
            <img src={URL.createObjectURL(productImage[0])} alt="preview" className="add-product-image" />
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="add-product-clear-icon"
              size="1x"
              onClick={() => {
                const emptyFile = new DataTransfer().files;
                if (additionProductImage2?.length) {
                  setValue(name, additionProductImage2);
                  setValue("additionalProduct2", emptyFile);
                  clearError(name);
                } else {
                  setValue(name, emptyFile);
                  clearError(name);
                }
              }}
            />
          </>
        ) : (
          <FontAwesomeIcon
            icon={faPlus}
            size="3x"
            color="#595e60"
            className="font-awesome-icon"
            onClick={() => {
              document.getElementById(name).click();
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "11rem",
          margin: "5px",
        }}
      >
        {errorMessage && (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              color: "#f11646",
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export function SubmitProductAdditionalImage2() {
  const name = "additionalProduct2";
  const { register, watch, setValue, errors, clearError } = useFormContext();
  const productImage = watch(name);
  const label = "Additional Product 2";
  const emptyMessage = "Add 1 more photos";

  const errorMessage = _.get(errors, `${name}.message`);

  useEffect(() => {}, [errors]);

  const validateFileSize = (file) => {
    const maxSize = 500 * 1024; // 500 kb

    if (file && file[0] && file[0].size > maxSize) {
      return "File size should not exceed 500 KB.";
    }

    return true;
  };

  return (
    <div>
      <Input
        className="d-none"
        name={name}
        id={name}
        type="file"
        accept=".jpeg,.png"
        innerRef={register({
          validate: validateFileSize,
        })}
        maxLength={1}
      />
      <div className="text-center p-2">
        <span className="submit-product-image-label">{productImage?.length ? label : emptyMessage}</span>
      </div>
      <div className={productImage?.length ? "add-product-image-container" : "add-product-image-container--dashed"}>
        {!_.isEmpty(productImage) ? (
          <>
            <img src={URL.createObjectURL(productImage[0])} alt="preview" className="add-product-image" />
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="add-product-clear-icon"
              size="1x"
              onClick={() => {
                const emptyFile = new DataTransfer().files;
                setValue(name, emptyFile);
                clearError(name);
              }}
            />
          </>
        ) : (
          <FontAwesomeIcon
            icon={faPlus}
            size="3x"
            color="#595e60"
            className="font-awesome-icon"
            onClick={() => {
              document.getElementById(name).click();
            }}
          />
        )}
      </div>
      <div
        style={{
          width: "11rem",
          margin: "5px",
        }}
      >
        {errorMessage && (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
              color: "#f11646",
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export function ReportSubmitProduct({ name, optionsList, label, requiredField, placeholder, id }) {
  const { errors, control } = useFormContext();

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      const viewportHeight = window.innerHeight;
      const scrollPosition = element.top - viewportHeight / 2 + element.height / 2;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [_.get(errors, `${name}.types.message`)]);

  return (
    <FormGroup
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Controller
        name={name}
        control={control}
        options={optionsList}
        mode="onChange"
        rules={[
          {
            required: requiredField,
          },
        ]}
        as={({ name, onChange, options, value }) => {
          return (
            <Select
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              name={name}
              onChange={onChange}
              options={options}
              value={value}
              placeholder={placeholder}
              styles={{
                control: (css) => ({
                  ...css,
                  width: 500,
                }),
                menu: ({ width, ...css }) => ({
                  ...css,
                  width: "max-content",
                  minWidth: "20%",
                }),
                option: (css) => ({ ...css, width: 500 }),
              }}
            />
          );
        }}
      />
      {_.get(errors, `${name}.types.message`) && (
        <div id={id}>
          <span className="submit-product-error-message">{_.get(errors, `${name}.types.message`)}</span>
        </div>
      )}
    </FormGroup>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.number),
};

RadioGroup.defaultProps = {
  name: "",
  options: [],
};
