/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "reactstrap";

import {
  DropdownInputSubmitProduct,
  MultiLineInput,
  ProductDropDown,
  SubmitProductAdditionalImage1,
  SubmitProductAdditionalImage2,
  SubmitProductImage,
  TextInputSubmitProduct,
} from "../../components/common/Forms";
import Header from "../../components/common/Header";
import {
  getProductBrands,
  getProductBrandsNext,
  getProductManufactures,
  getProductManufacturesNext,
  safelistGetProductCategories,
  safelistSubmitProduct,
} from "../../redux/actions/safeList";
import "./SubmitProduct.scss";

export default function SubmitProduct({ isPhysician, activeTab }) {
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
  });
  const watchAll = methods.watch();
  const additionalProduct1 = methods.watch("additionalProduct1");
  const productCategory = methods.watch("productCategory");
  const productCategoryLevel1 = methods.watch("productCategoryLevel1");
  const productCategoryLevel2 = methods.watch("productCategoryLevel2");

  const categories = useSelector((s) => s.safeList.productCategories);
  const brandsLoading = useSelector((s) => s.safeList.productBrandsLoading);
  const manufacturerLoading = useSelector((s) => s.safeList.productManufacturesLoading);
  const addProductSubmitting = useSelector((state) => state.safeList.addProductSubmitting);

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedManufacturers, setSelectedManufacturers] = useState("");

  const [level1ProductCategoryList, setLevel1ProductCategory] = useState([]);
  const [level2ProductCategoryList, setLevel2ProductCategory] = useState([]);

  const [brandSearchValue, setBrandSearchValue] = useState("");
  const [manufacturerSearchValue, setManufacturerSearchValue] = useState("");

  const brandValueRef = useRef(null);
  const manufacturerValueRef = useRef(null);

  const [characterCount, setCharacterCount] = useState(0);

  const brands = useSelector((s) => s.safeList.productBrands);
  const isFetchingBrand = useSelector((state) => state.safeList.isFetchingBrand);
  const isFetchingBrandNext = useSelector((state) => state.safeList.isFetchingBrandNext);

  const manufacturer = useSelector((s) => s.safeList.productManufactures);
  const isFetchingManufacturer = useSelector((state) => state.safeList.isFetchingManufacturer);
  const isFetchingManufacturerNext = useSelector((state) => state.safeList.isFetchingManufacturerNext);

  useEffect(() => {
    dispatch(safelistGetProductCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getProductBrands({ searchValue: brandSearchValue }));
  }, [brandSearchValue]);

  useEffect(() => {
    dispatch(getProductManufactures({ searchValue: manufacturerSearchValue }));
  }, [manufacturerSearchValue]);

  useEffect(() => {}, [selectedBrand, selectedManufacturers]);

  const formReset = () => {
    brandValueRef?.current?.clearValue();
    manufacturerValueRef?.current?.clearValue();
    setLevel1ProductCategory([]);
    setLevel2ProductCategory([]);
    methods.reset();
  };

  useEffect(() => {
    if (!_.isEmpty(productCategory)) {
      const selectedProductCategory = _.find(categories, (item) => item.id == productCategory.id);
      setLevel1ProductCategory(_.get(selectedProductCategory, "children"));
      methods.setValue("productCategoryLevel1", "");
      methods.setValue("productCategoryLevel2", "");
    } else {
      setLevel1ProductCategory([]);
      setLevel2ProductCategory([]);
      methods.setValue("productCategoryLevel1", "");
      methods.setValue("productCategoryLevel2", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCategory]);

  useEffect(() => {
    if (!_.isEmpty(productCategoryLevel1)) {
      setLevel2ProductCategory(_.get(productCategoryLevel1, "children"));
      methods.setValue("productCategoryLevel2", "");
    } else {
      setLevel2ProductCategory([]);
    }
  }, [productCategoryLevel1]);

  const handleCommentsCharacter = (event) => {
    const value = event.target.value;
    setCharacterCount(value.length);
  };

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const productImageFields = [
    {
      name: "productPhoto1",
      label: "Product Image(Front)",
      required: true,
    },
    {
      name: "ingredientsPhoto",
      label: "Ingredients",
      required: true,
    },
    {
      name: "barCodePhoto",
      label: "Barcode",
      required: true,
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAll.productParentCategory, watchAll.productCategory]);

  useEffect(() => {
    if (isPhysician) {
      if (activeTab === 3) {
        formReset();
      }
    }
  }, [activeTab]);

  const onSubmit = (product) => {
    if (!_.isEmpty(product.productCategoryLevel2)) {
      product["category"] = _.get(product, "productCategoryLevel2.id");
    } else if (!_.isEmpty(product.productCategoryLevel1)) {
      if (_.get(product, "productCategoryLevel1.children").length > 0) {
        methods.setError("productCategoryLevel2", {
          type: "custom",
          message: "Please select a sub category",
        });

        return;
      } else {
        product["category"] = _.get(product, "productCategoryLevel1.id");
      }
    } else {
      if (_.get(product, "productCategory.children", []).length > 0) {
        methods.setError("productCategoryLevel1", {
          type: "custom",
          message: "Please select a sub category",
        });

        return;
      } else {
        product["category"] = _.get(product, "productCategory.id");
      }
    }

    if (!_.isEmpty(selectedBrand)) {
      product["brand"] = selectedBrand.id;
    }
    if (!_.isEmpty(selectedManufacturers)) {
      product["manufacturer"] = selectedManufacturers.id;
    }

    dispatch(
      safelistSubmitProduct({
        product,
        history,
        isPhysician,
        formReset,
      }),
    );
  };

  const handleSearchNextProductBrand = () => {
    dispatch(getProductBrandsNext(brandSearchValue));
  };

  const handleSearchNextManufacturer = () => {
    dispatch(getProductManufacturesNext(manufacturerSearchValue));
  };

  return (
    <div className="submit-product">
      {!isPhysician && <Header showBack={true} title="Submit Product" />}
      <div className={!isPhysician ? "pt-80 col-lg-8 offset-lg-2" : "p-50 pt-0"}>
        {isPhysician && (
          <div className="text-center my-2">
            <h1>Suggest a Product</h1>
          </div>
        )}
        <h5 className="text-center py-1 px-5" style={{ color: "#595e60" }}>
          Complete the form below to submit your product to CAMP. After verification, you will be notified if the
          product is added to CAMP.
        </h5>
        <FormContext {...methods}>
          <Form className="product-submit-form" onSubmit={methods.handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <TextInputSubmitProduct
                  label="Product Name"
                  name="name"
                  placeholder="Product Name"
                  requiredField={true}
                />
              </Col>
              <Col>
                <ProductDropDown
                  label="Brand Name"
                  name="brandName"
                  placeholder="Select Brand"
                  className="form-fields"
                  optionsList={brands}
                  setValue={setSelectedBrand}
                  isClearable={true}
                  isSearchable={true}
                  isLoading={isFetchingBrand || isFetchingBrandNext}
                  onSearch={(value) => {
                    setBrandSearchValue(value);
                  }}
                  fieldRef={brandValueRef}
                  handleNext={handleSearchNextProductBrand}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ProductDropDown
                  id="productManufacturer"
                  name="productManufacturer"
                  label="Manufacturer Name"
                  placeholder="Select Manufacturer"
                  optionsList={manufacturer}
                  setValue={setSelectedManufacturers}
                  isClearable={true}
                  isSearchable={true}
                  isLoading={isFetchingManufacturer || isFetchingManufacturerNext}
                  onSearch={(value) => {
                    setManufacturerSearchValue(value);
                  }}
                  fieldRef={manufacturerValueRef}
                  handleNext={handleSearchNextManufacturer}
                />
              </Col>
              <Col>
                <DropdownInputSubmitProduct
                  id="productCategory"
                  optionsList={categories}
                  name="productCategory"
                  label="Product Category"
                  placeholder="Select Product Category"
                />
              </Col>
            </Row>
            {level1ProductCategoryList?.length > 0 && (
              <Row>
                <Col>
                  <DropdownInputSubmitProduct
                    id="productCategoryLevel1"
                    optionsList={level1ProductCategoryList}
                    name="productCategoryLevel1"
                    label="Product Sub Category Level I"
                    placeholder="Select Product Category"
                    requiredField={level1ProductCategoryList?.length > 0}
                  />
                </Col>
                <Col>
                  {level2ProductCategoryList?.length > 0 && (
                    <DropdownInputSubmitProduct
                      id="productCategoryLevel2"
                      optionsList={level2ProductCategoryList}
                      name="productCategoryLevel2"
                      label="Product Sub Category Level II"
                      placeholder="Select Product Category"
                      requiredField={level2ProductCategoryList?.length > 0}
                    />
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <MultiLineInput
                  label="Comments&nbsp;"
                  name="description"
                  onChange={handleCommentsCharacter}
                  placeholder="Please add any additional information to help us verify this product, including brand, manufacturer, or website."
                  requiredField={false}
                  rows={3}
                />
                <div>
                  <span style={characterCount === 250 ? { color: "red" } : { color: "#595e60" }}>
                    {characterCount}/{250} characters
                  </span>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Row>
                <span className="submit-product-title">Upload Product Images</span>
              </Row>
              <Row className="mt-2">
                <span className="submit-product-sub-title">
                  Please upload pictures of the product including the front and back of the label, ingredients and
                  barcode. Make sure your pictures are, clear and in focus.
                </span>
              </Row>
            </Row>

            <div className="product-image-upload-box">
              {productImageFields.map((item) => (
                <SubmitProductImage key={item.name} name={item.name} required={item.required} label={item.label} />
              ))}
              <SubmitProductAdditionalImage1 />
              {!!additionalProduct1?.length && <SubmitProductAdditionalImage2 />}
            </div>
            <Row
              className="btn-actions mb-3 pb-4 mt-2"
              style={{ justifyContent: "center", alignItems: "center", gap: 20 }}
            >
              <Button
                onClick={isPhysician ? formReset : goBack}
                style={{
                  backgroundColor: "#f4fbff",
                  color: "#00c9b7",
                  width: 200,
                  maxWidth: 200,
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={addProductSubmitting}
                style={{
                  width: 200,
                  maxWidth: 200,
                }}
              >
                Submit Product
              </Button>
            </Row>
          </Form>
        </FormContext>
      </div>
    </div>
  );
}
