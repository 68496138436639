import React from "react";
import { Popover, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { BARCODE_MATCHES_STATUS } from "../../constants/constants";
import { MantineReactTable } from "mantine-react-table";

export default function BarcodeMatchesPopover({ opened, setOpened }) {
  const barcodeMatches = useSelector((state) => state.productReviews.barcodeMatches);
  const barcodeMatchesError = useSelector((state) => state.productReviews.barcodeMatchesError);
  const { id } = useParams();

  const filteredBarcodeMatches = barcodeMatches?.filter((match) => match.id.toString() !== id) || [];

  const columns = [
    {
      accessorKey: "name",
      header: "Product Name",
      size: 100,
    },
    {
      accessorKey: "status",
      header: "Current Status",
      size: 100,
      Cell: ({ row }) => BARCODE_MATCHES_STATUS[row.original.status],
    },
    {
      accessorKey: "actions",
      header: "",
      size: 100,
      Cell: ({ row }) => (
        <Link
          to={`/product-details/${row.original.id}`}
          onClick={() => setOpened(false)}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#00c9b7",
            textDecoration: "none",
            fontSize: "14px",
          }}
        >
          View Details
        </Link>
      ),
    },
  ];

  return (
    <>
      {filteredBarcodeMatches.length > 0 && !barcodeMatchesError && (
        <Popover opened={opened} onChange={setOpened} position="bottom-end" width={400} withArrow shadow="md">
          <Popover.Target>
            <Text
              component="span"
              sx={{
                cursor: "pointer",
                color: "#00c9b7",
                fontSize: "13px",
                padding: "0 8px",
                whiteSpace: "nowrap",
                display: "block",
                width: "90px",
                textAlign: "center",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => setOpened(true)}
            >
              View Possible Matches
            </Text>
          </Popover.Target>

          <Popover.Dropdown>
            <MantineReactTable
              columns={columns}
              data={filteredBarcodeMatches}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              enableSorting={false}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              mantineTableProps={{
                fontSize: "sm",
                highlightOnHover: true,
              }}
              state={{ density: "xs" }}
            />
          </Popover.Dropdown>
        </Popover>
      )}
    </>
  );
}
